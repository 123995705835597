import React from "react";
import { useTranslation } from 'react-i18next';

// Layout & SEO
import Layout from "../components/layout"
import SEO from "../components/seo"

// Components
import { CasePreviewWithEnterance } from "../components/casePreview";

import './portfolio.scss';

const PortfolioPage = ({ data }) => {
  const { t, i18n } = useTranslation();

  const dataLocale = data.allMarkdownRemark.edges.filter((edge) => {
    return i18n.language.includes(edge.node.frontmatter.language);
  })

  return (
  <Layout>
    <SEO title="Portfolio" />
      <div className='portfolioPage page'>
        <h1
          className='portfolioPage__heading'
        >
          {t("portfolioPage.header.heading")}
        </h1>
        <div className='portfolioPage__casesContainer'>
        {
        dataLocale.map(edge => (
          <CasePreviewWithEnterance
            key={edge.node.id}
            portfolioCase={edge}
            locale={i18n.language}
          />
        ))
      }
        </div>
      </div>
  </Layout>
  )
}

export default PortfolioPage;

export const query = graphql`
query {
  allMarkdownRemark(filter: {frontmatter: {postType: {eq: "portfolio-case"}}}, 
    sort: { fields: [frontmatter___date], order: DESC }) {
    edges {
      node {
        id
        excerpt
        frontmatter {
          filename
          language
          frontPage
          description
          date
          title
          path
          description
          roles
          frontPageImg
        }
      }
    }
  }
}
`